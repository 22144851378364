import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/* It would be most beneficial to set the ts-cacheable global defaults here instead of specifying the same stuff for nearly every method decorator
 * However, due to the way Angular does the initialization, it's already too late by the time this code executes :-/
 * See also:  https://github.com/angelnikolov/ts-cacheable/issues/100 
 * 
 * // Set the default storage mode to localStorage so that it is shared "application wide"
 * GlobalCacheConfig.storageStrategy = LocalStorageStrategy; 
 */

if (environment.production) {
   enableProdMode();
}

platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .then(() => {
      console.debug("Bootstrapping complete");
      document.querySelector("#initError").remove();
   })
   .catch(err => {
      console.error("Bootstrapping aborted!");
      
      let msg = 'Error during application initialization'
      msg += err ? ': ' + err : '.';
      msg += '<br><small>More details may be available in console.</small>'
      document.querySelector("#initErrorMessage").innerHTML = msg;
   })
   .finally(() => {
      document.querySelector("#initLoader").remove();
   })
;
